// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achievements{
  display: flex;
  flex-direction: row;
  background-color: #D9D9D9;
  justify-content: center;
  align-items: center;
  margin:0px 0px;
  padding: 20px 10px;
}

.image{
margin:0px 15px;
background-size: 100%;
height:420px;
width:50%;
}

@media only screen and (max-width: 600px) {
  .image{
    margin:5px 5px;
    background-size: 100%;
    height:160px;
    width:50%;
    } 
}
@media only screen and (max-width: 900px) and (min-width: 600px)  {
  .image{
    height:300px;
    } 
}`, "",{"version":3,"sources":["webpack://./src/components/Achievments/Achievments.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB;;AAEA;AACA,eAAe;AACf,qBAAqB;AACrB,YAAY;AACZ,SAAS;AACT;;AAEA;EACE;IACE,cAAc;IACd,qBAAqB;IACrB,YAAY;IACZ,SAAS;IACT;AACJ;AACA;EACE;IACE,YAAY;IACZ;AACJ","sourcesContent":[".achievements{\n  display: flex;\n  flex-direction: row;\n  background-color: #D9D9D9;\n  justify-content: center;\n  align-items: center;\n  margin:0px 0px;\n  padding: 20px 10px;\n}\n\n.image{\nmargin:0px 15px;\nbackground-size: 100%;\nheight:420px;\nwidth:50%;\n}\n\n@media only screen and (max-width: 600px) {\n  .image{\n    margin:5px 5px;\n    background-size: 100%;\n    height:160px;\n    width:50%;\n    } \n}\n@media only screen and (max-width: 900px) and (min-width: 600px)  {\n  .image{\n    height:300px;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
