// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #B80000;
    font-size: 16px;
    font-weight: 400px;
    font-family: 'Domine', serif;
}

@media only screen and (max-width: 600px) {
    .Footer{
        font-size: 15px;
        padding: 10px 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI;QACI,eAAe;QACf,kBAAkB;IACtB;EACF","sourcesContent":[".Footer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    background-color: #B80000;\n    font-size: 16px;\n    font-weight: 400px;\n    font-family: 'Domine', serif;\n}\n\n@media only screen and (max-width: 600px) {\n    .Footer{\n        font-size: 15px;\n        padding: 10px 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
