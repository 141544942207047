import './NotFound.css'
function NotFound(props) {

  return (
   <>
   <div className='found' style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'100px 100px',fontFamily:'Domile'}}>
    404 Not Found
   </div>
   </>
  );
}

export default NotFound;

