// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.found{
font-size: 80px;
}

@media only screen and (max-width: 900px) and (min-width:500px){
    .found{
        font-size: 40px;
        }
  }

  @media only screen and (max-width: 500px){
    .found{
        font-size: 20px;
        }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Not_found/NotFound.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf;;AAEA;IACI;QACI,eAAe;QACf;EACN;;EAEA;IACE;QACI,eAAe;QACf;EACN","sourcesContent":[".found{\nfont-size: 80px;\n}\n\n@media only screen and (max-width: 900px) and (min-width:500px){\n    .found{\n        font-size: 40px;\n        }\n  }\n\n  @media only screen and (max-width: 500px){\n    .found{\n        font-size: 20px;\n        }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
